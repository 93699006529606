<template>
    <LiefengContent>
        <template v-slot:title>注销记录</template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input :maxlength="20" enter-button v-model.trim="searchData.groupName" placeholder="请输入群组名称" style="margin-right: 10px; width: 150px" />
                </FormItem>
                <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="getList({ page: 1, pageSize: 20 })">搜索</Button>
                <Button style="margin-right: 10px" type="success" icon="ios-refresh" @click="rest">重置</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" height="200" :loading="loading" :fixTable="true" :curPage="curPage" :total="total" :page-size="pageSize" :pagesizeOpts="[20, 30, 50, 100]" @hadlePageSize="hadlePageSize"></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import { format } from "@/utils/formatTime.js"
export default {
    data() {
        return {
            searchData: {},
            loading: false,
            total: 0,
            rootData: {},
            tableData: [],
            talbeColumns: [
                {
                    title: "组范围",
                    minWidth: 130,
                    key: "scopeName",
                    align: "center",
                },
                {
                    title: "群组名称",
                    key: "groupName",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "群组成员数",
                    key: "userCount",
                    width: 130,
                    align: "center",
                },
                {
                    title: "创建时间",
                    key: "gmtCreate",
                    align: "center",
                    width: 170,
                },
                {
                    title: "群组类型",
                    align: "center",
                    width: 170,
                    render: (h, params) => {
                        return h("span", this.groupList?.find(v => v.value === params.row.groupType)?.label || "-")
                    },
                },
                {
                    title: "消息数",
                    key: "chatRecordCount",
                    width: 100,
                    align: "center",
                },
                {
                    title: "操作",
                    width: 250,
                    align: "center",
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    justifyContent: "center",
                                },
                            },
                            [
                                h(
                                    "Dropdown",
                                    {
                                        props: {
                                            transfer: true,
                                        },
                                    },
                                    [
                                        h(
                                            "Button",
                                            {
                                                props: {
                                                    type: "info",
                                                    size: "small",
                                                },
                                                nativeOn: {
                                                    click: () => {
                                                        this.$post(
                                                            "/sendmsg/pc/chat/groupCreate/changeGroup",
                                                            {
                                                                groupId: params.row.groupId,
                                                                archiveStatus: 1, //1未归档 2归档
                                                            },
                                                            { "Content-Type": "application/json" }
                                                        ).then(res => {
                                                            if (res && res.code == 200) {
                                                                this.$Message.success({
                                                                    content: "操作成功",
                                                                    background: true,
                                                                })
                                                                this.getList({
                                                                    pageSize: this.pageSize,
                                                                    page: this.curPage,
                                                                })
                                                                return
                                                            }
                                                        })
                                                    },
                                                },
                                            },
                                            "恢复"
                                        ),
                                    ]
                                ),
                                h(
                                    "Button",
                                    {
                                        nativeOn: {
                                            click: () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: `${params.row.groupName}`,
                                                    content: `/page#/groupdetail?groupId=${params.row.groupId}&groupName=${params.row.groupName}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            textAlign: "center",
                                            marginLeft: "20px",
                                        },
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                    },
                                    "查看消息"
                                ),
                                h(
                                    "Button",
                                    {
                                        nativeOn: {
                                            click: () => {
                                                this.$Modal.confirm({
                                                    title: "温馨提示",
                                                    content: "您正在删除当前群组，是否继续",
                                                    onOk: () => {
                                                        this.$post("/sendmsg/pc/chat/groupCreate/deleteGroup", {
                                                            groupId: params.row.groupId,
                                                        }).then(res => {
                                                            if (res && res.code == 200) {
                                                                this.$Message.success({
                                                                    content: "操作成功",
                                                                    background: true,
                                                                })
                                                                this.getList({
                                                                    pageSize: this.pageSize,
                                                                    page: this.curPage,
                                                                })
                                                                return
                                                            }
                                                        })
                                                    },
                                                })
                                            },
                                        },
                                        style: {
                                            textAlign: "center",
                                            marginLeft: "20px",
                                        },
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                    },
                                    "删除"
                                ),
                            ]
                        )
                    },
                },
            ],
            selection: [],
            showDo: true,
            hidenBread: true,
            hideSearch: false,
            curPage: 1,
            pageSize: 20,
            groupList: [],
        }
    },
    methods: {
        // 重置
        rest() {
            this.searchData = {}
            let data = {
                page: 1,
                pageSize: 20,
            }
            this.getList(data)
        },
        //菜单树
        async getList(data) {
            this.tableData = []
            this.loading = true
            await this.$get("/sendmsg/pc/chat/groupCreate/selectPcPage", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                groupName: this.searchData.groupName,
                groupType: this.searchData.groupType,
                orgCodeSelf: this.rootData.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                pageSize: data.pageSize,
                page: data.page,
                archiveStatus: 2, // 1未归档 2已归档
                status: 1, //1已删除 2未删除
            })
                .then(res => {
                    if (res.code === "200") {
                        res.dataList.map((item, index, arr) => {
                            arr[index].gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm:ss")
                            this.groupList.map(items => {
                                if (item.groupType == items.value) {
                                    item.groupTypeName = items.label
                                }
                            })
                            item.scopeName = item.scopeName ? item.scopeName.replace(/\//g, "") : ""
                            item.chatGroupManager = item.manager && item.manager.length ? item.manager.join(",") : ""
                        })
                        this.tableData = res.dataList
                        this.total = res.maxCount
                        this.curPage = res.currentPage
                        this.pageSize = res.pageSize
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: "数据获取失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        //分页器
        hadlePageSize(obj) {
            this.getList(obj)
        },
        async getSelect() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                let data = res.dataList
                this.groupList = []
                data.map(item => {
                    // if (item.groupType != "1") {
                    this.groupList.push({
                        value: item.dictKey,
                        label: item.dictValue,
                    })
                    // }
                })
            })
        },
    },
    async created() {
        this.rootData = parent.vue.loginInfo.userinfo
        let data = {
            page: 1,
            pageSize: 20,
        }
        await this.getSelect()
        await this.getList(data)
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>

<style scoped lang="less"></style>
